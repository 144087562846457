import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useState } from "react";
import { Card, CardBody, CardHeader, Input } from "reactstrap";
import ".././CSS/MonthYearPicker.css";

export const MonthYearPicker = ({ pickYear, getMonth, getYear, data }) => {
  const [month, setMonth] = useState(8);
  const [year, setYear] = useState(2024);
  const [monthUpperBound, setMonthUpperBound] = useState(8);
  const [monthLowerBound, setMonthLowerBound] = useState(12);
  const [yearUpperBound, setYearUpperBound] = useState(2024);
  const [yearLowerBound, setYearLowerBound] = useState(2023);

  const getSetup = () => {
    let mub = monthUpperBound;
    let mlb = monthLowerBound;
    let yub = yearUpperBound;
    let ylb = yearLowerBound;

    for (let item of data) {
      if (item.year <= ylb) {
        ylb = item.year;
        if (item.month + 1 < mlb) {
          mlb = item.month + 1;
        }
      } else if (item.year >= yub) {
        yub = item.year;
        if (item.month + 1 > mub) {
          mub = item.month + 1;
        }
      }
    }
    console.log(month);
    setMonthLowerBound(mlb);
    setMonthUpperBound(mub);
    setYearLowerBound(ylb);
    setYearUpperBound(yub);
    setMonth(mub - 1);
    setYear(yub);
  };

  const updateValue = (left) => {
    getSetup();
    if (pickYear) {
      if (left && year > yearLowerBound) {
        setYear(year - 1);
        getYear(year - 1);
      } else if (!left && year < yearUpperBound) {
        setYear(year + 1);
        getYear(year + 1);
      }
    }
    if (left) {
      if (month - 1 >= monthLowerBound || year > yearLowerBound) {
        if (month - 1 === 0) {
          setYear(year - 1);
          getYear(year - 1);
          setMonth(12);
          getMonth(12);
        } else {
          getMonth(month - 1);
          setMonth(month - 1);
        }
      }
    } else if (!left) {
      if (month + 1 <= monthUpperBound || year < yearUpperBound) {
        if (month + 1 > 12) {
          setYear(year + 1);
          getYear(year + 1);
          setMonth(1);
          getMonth(1);
        } else if (month + 1 < monthUpperBound) {
          getMonth(month + 1);
          setMonth(month + 1);
        }
      }
    } else {
      getMonth(month);
      getYear(year);
    }
  };

  const getShownValue = () => {
    if (pickYear) {
      return "" + year;
    }
    if (month + 1 < 10) {
      return year + "-0" + (month + 1);
    } else return year + "-" + (month + 1);
  };

  useEffect(() => {
    updateValue(false);
  }, []);

  return (
    <>
      <motion.div className="myp-container mx-auto fit-content">
        <motion.img
          src="icons/left-arrow.svg"
          alt="Left Arrow"
          className="myp-arrow clickable ms-3"
          onClick={() => {
            updateValue(true);
          }}
        />
        <motion.div className="myp-content">{getShownValue()}</motion.div>
        <motion.img
          src="icons/right-arrow.svg"
          alt="Right Arrow"
          className="myp-arrow clickable"
          onClick={() => {
            updateValue(false);
          }}
        />
      </motion.div>
    </>
  );
};
