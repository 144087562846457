import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import ".././CSS/ColoredSelector.css";

export const ColoredSelector = ({ title, color }) => {
  return (
    <>
      <motion.div className="cs-container clickable">
        <motion.div className="mx-auto cs-body">
          <motion.div
            className="dot-circle"
            style={{ backgroundColor: color() }}
          ></motion.div>
          <span className="cs-title">{title}</span>
        </motion.div>
      </motion.div>
    </>
  );
};
