import { Card, CardBody, CardHeader, ButtonGroup } from "reactstrap";
import { Line, Bar } from "react-chartjs-2";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { gql } from "graphql-request";
import ".././CSS/Historical.css";
import { ColoredSelector } from "./ColoredSelector";
import { DatePicker } from "./DatePicker";
import { getName } from "../helpers";
import { MonthYearPicker } from "./MonthYearPicker";
import { ValueCard } from "./ValueCard";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      labels: {
        usePointStyle: true,
        font: {
          size: 16,
          family: "Nunito",
        },
      },
    },
    title: {
      display: true,
      text: "Historical Data",
      font: {
        size: 30,
        weight: "bold",
        family: "Nunito",
      },
    },
  },
  scales: {
    y: {
      min: 0,
    },
  },
};

export const Historical = ({ towerInfo, client }) => {
  const [graphColor, setGraphColor] = useState("#ecac5cff");
  const [dataInterval, setDataInterval] = useState(0);
  const [selected, setSelected] = useState("power_output");
  const [startDate, setStartDate] = useState(new Date());
  const [month, setMonth] = useState(8);
  const [year, setYear] = useState(2024);
  const [timeSelection, setTimeSelection] = useState("daily");
  const [energyData, setEnergyData] = useState([]);
  const [lData, setLData] = useState([0]);
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        fill: true,
        label: "",
        data: [],
        borderColor: graphColor,
        backgroundColor: graphColor.substring(0, 7) + "aa",
      },
    ],
  });

  useEffect(() => {
    resetData();
    const queryBackend = async () => {
      var before = new Date(startDate);
      if (before.getDate() !== new Date().getDate()) {
        before.setHours(23);
        before.setMinutes(59);
      } else {
        before = new Date();
      }
      const after = startDate;
      after.setHours(0);
      after.setMinutes(0);

      const query = gql`{
        telemetry(after: "${after.toISOString()}", before: "${before.toISOString()}"){
          date_time
          ${selected}
        }
      }`;
      const energyNow = gql`
        {
          energy(tower_id: 0) {
            energy
            day
            month
            year
          }
        }
      `;

      var hData = [];
      var eData = [];

      try {
        hData = (await client.request(query)).telemetry;
        eData = (await client.request(energyNow)).energy;
      } catch (exception) {
        hData = null;
        eData = null;
        console.log(exception);
      }

      if (hData !== null)
        if (timeSelection === "total") {
          const loaded_data = [];
          const labels = [];
          for (let item of eData) {
            if (!labels.includes(item.year)) {
              labels.push(item.year);
              loaded_data.push(0);
              loaded_data[labels.indexOf(item.year)] += item.energy;
            } else {
              loaded_data[labels.indexOf(item.year)] += item.energy;
            }
          }
          setLData(loaded_data);
          setData({
            labels,
            datasets: [
              {
                label: "Monthly Energy (kWh)",
                data: loaded_data,
                backgroundColor: graphColor.substring(0, 7) + "aa",
              },
            ],
          });
        } else if (timeSelection === "yearly") {
          const loaded_data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
          for (let item of eData) {
            if (item.year === year) {
              loaded_data[item.month] += item.energy;
            }
          }
          setLData(loaded_data);
          setData({
            labels,
            datasets: [
              {
                label: "Monthly Energy (kWh)",
                data: loaded_data,
                backgroundColor: graphColor.substring(0, 7) + "aa",
              },
            ],
          });
        } else if (timeSelection === "monthly") {
          const loaded_data = [];
          const labels = [];
          for (let item of eData) {
            if (item.month === month && item.year === year) {
              loaded_data.push(item.energy);
              labels.push(item.day);
            }
          }
          setLData(loaded_data);
          setData({
            labels,
            datasets: [
              {
                label: "Monthly Energy (kWh)",
                data: loaded_data,
                backgroundColor: graphColor.substring(0, 7) + "aa",
              },
            ],
          });
        } else if (timeSelection === "daily") {
          const labels = hData.map((item) => {
            let date = new Date(item.date_time);
            return date.getHours() + ":" + date.getMinutes();
          });
          const loaded_data = hData.map((item) => item[selected]);
          setData({
            labels,
            datasets: [
              {
                fill: true,
                label: getName(selected),
                data: loaded_data,
                borderColor: graphColor,
                backgroundColor: graphColor.substring(0, 7) + "aa",
              },
            ],
          });
        }

      setEnergyData(eData);
    };
    clearInterval(dataInterval);
    var interval = setInterval(queryBackend, 1000);
    setDataInterval(interval);
    return () => clearInterval(interval);
  }, [selected, startDate, timeSelection, month, year]);

  const resetData = () => {
    setLData([0]);
    setData({
      labels: [],
      datasets: [
        {
          fill: true,
          label: "",
          data: [],
          borderColor: graphColor,
          backgroundColor: graphColor.substring(0, 7) + "aa",
        },
      ],
    });
  };

  return (
    <>
      <Card className="historical-container shadow">
        <CardBody className="d-flex">
          <motion.div className="flex-column flex-grow-1">
            {timeSelection === "daily" && (
              <Line options={options} data={data} />
            )}
            {(timeSelection === "monthly" ||
              timeSelection === "yearly" ||
              timeSelection === "total") && (
              <Bar options={options} data={data} />
            )}
          </motion.div>
          <motion.div className="flex-column d-flex h-column flex-wrap">
            <motion.div className="flex-row d-flex h-row1">
              <ButtonGroup className="historical-selector mx-auto">
                <motion.button
                  className="btn button-color shadow"
                  onClick={() => setTimeSelection("daily")}
                >
                  Daily
                </motion.button>
                <motion.button
                  className="btn button-color shadow"
                  onClick={() => setTimeSelection("monthly")}
                >
                  Monthly
                </motion.button>
                <motion.button
                  className="btn button-color shadow"
                  onClick={() => setTimeSelection("yearly")}
                >
                  Yearly
                </motion.button>
                <motion.button
                  className="btn button-color shadow"
                  onClick={() => setTimeSelection("total")}
                >
                  Total
                </motion.button>
              </ButtonGroup>
            </motion.div>
            <motion.div className="flex-row d-flex h-row2">
              <motion.div className="flex-column ms-3 h-column1">
                {timeSelection === "daily" && (
                  <div>
                    <div
                      onClick={() => {
                        setSelected("power_output");
                        setGraphColor("#ecac5c");
                      }}
                    >
                      <ColoredSelector
                        title={"Power Output"}
                        color={() => {
                          if (selected === "power_output") return "#ecac5c";
                          else return "#7a756f";
                        }}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setSelected("angle");
                        setGraphColor("#ac5cec");
                      }}
                    >
                      <ColoredSelector
                        title={"Angle"}
                        color={() => {
                          if (selected === "angle") return "#ac5cec";
                          else return "#7a756f";
                        }}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setSelected("solar_flux");
                        setGraphColor("#ffc16a");
                      }}
                    >
                      <ColoredSelector
                        title={"Solar Resistance"}
                        color={() => {
                          if (selected === "solar_flux") return "#ffc16a";
                          else return "#7a756f";
                        }}
                      />
                    </div>
                  </div>
                )}
                {timeSelection === "monthly" && (
                  <div className="mx-auto w-50">
                    <ValueCard
                      title={"Monthly Energy (kWh)"}
                      value={lData
                        .reduce((total, num) => {
                          return (total += num);
                        })
                        .toFixed(2)}
                      iconName={"icons/lightning-bolt.svg"}
                      altText={"lightning bolt"}
                    />
                  </div>
                )}
                {timeSelection === "yearly" && (
                  <div className="mx-auto w-50 mb-5">
                    <ValueCard
                      title={"Yearly Energy (kWh)"}
                      value={lData
                        .reduce((total, num) => {
                          return (total += num);
                        })
                        .toFixed(2)}
                      iconName={"icons/lightning-bolt.svg"}
                      altText={"lightning bolt"}
                    />
                  </div>
                )}
                {timeSelection === "total" && (
                  <div className="mx-auto w-50 mb-5">
                    <ValueCard
                      title={"Total Energy (kWh)"}
                      value={lData
                        .reduce((total, num) => {
                          return (total += num);
                        })
                        .toFixed(2)}
                      iconName={"icons/lightning-bolt.svg"}
                      altText={"lightning bolt"}
                    />
                  </div>
                )}
              </motion.div>
              {timeSelection === "daily" && (
                <motion.div className="flex-column h-column1">
                  <div>
                    <div
                      onClick={() => {
                        setSelected("humidity");
                        setGraphColor("#5c9cec");
                      }}
                    >
                      <ColoredSelector
                        title={"Humidity"}
                        color={() => {
                          if (selected === "humidity") return "#5c9cec";
                          else return "#7a756f";
                        }}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setSelected("temperature");
                        setGraphColor("#fc4848");
                      }}
                    >
                      <ColoredSelector
                        title={"Temperature"}
                        color={() => {
                          if (selected === "temperature") return "#fc4848";
                          else return "#7a756f";
                        }}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setSelected("pressure");
                        setGraphColor("#dadad9");
                      }}
                    >
                      <ColoredSelector
                        title={"Atmospheric Pressure"}
                        color={() => {
                          if (selected === "pressure") return "#dadad9";
                          else return "#7a756f";
                        }}
                      />
                    </div>
                  </div>
                </motion.div>
              )}
            </motion.div>
            <motion.div className="flex-row ms-4 h-row1">
              {timeSelection === "daily" && (
                <DatePicker
                  chooseDate={(date) => {
                    setStartDate(date);
                  }}
                />
              )}
              {timeSelection === "monthly" && (
                <MonthYearPicker
                  data={energyData}
                  pickYear={false}
                  getMonth={(m) => {
                    setMonth(m);
                  }}
                  getYear={(y) => {
                    setYear(y);
                  }}
                />
              )}
              {timeSelection === "yearly" && (
                <MonthYearPicker
                  data={energyData}
                  pickYear={true}
                  getYear={(y) => {
                    console.log(y);
                    setYear(y);
                  }}
                  getMonth={() => {}}
                />
              )}
            </motion.div>
          </motion.div>
        </CardBody>
      </Card>
    </>
  );
};
